import { parseAndFormatDate } from '@/utils/dateHelper';
import cx from 'classnames';
import React from 'react';
import { IAuthor } from 'types/cms';

import styles from './style.module.scss';

interface AuthorProps {
  author?: IAuthor | null;
  publishedDate: string;
  fontColor?: string;
}

export const ArticlePublisher = ({ author, publishedDate, fontColor }: AuthorProps) => {
  return (
    <div className={cx(styles.publish, fontColor && styles[fontColor])}>
      Article by {author?.name} |{' '}
      <span className={styles.date}>{parseAndFormatDate(publishedDate, 'DD MMM YYYY')}</span>
    </div>
  );
};
