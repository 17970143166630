import CMSChildrenContainer from '@/cmsComponents/ChildrenContainer';
import ImageSlider from '@/cmsComponents/ImageSlider';
import { GridDirection } from '@/cmsComponents/constants';
import cmsStyles from '@/cmsComponents/style.module.scss';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';
import { IArticleSummary } from 'types/cms';

import Grid from '@material-ui/core/Grid';

import ArticleSnippetItem from './ArticleSnippetItem';
import styles from './style.module.scss';

const BackgroundBannerImage = dynamic<any>(() =>
  import('components/BackgroundBannerImage').then(mod => mod.default),
);
export enum ArticleSnippetStyle {
  FullWidth = 'Full_Width',
  Vertical = 'One_Column',
  TwoColumns = 'Two_Column',
  ThreeColumns = 'Three_Column',
  SlidesShow = 'Slides_Show',
}

const styleToGridColumn = (style: ArticleSnippetStyle) => {
  switch (style) {
    case ArticleSnippetStyle.ThreeColumns:
      return 4;
    case ArticleSnippetStyle.TwoColumns:
      return 6;
    default:
      return 12;
  }
};
const styleToGridDirection = (style: ArticleSnippetStyle) => {
  switch (style) {
    case ArticleSnippetStyle.TwoColumns:
    case ArticleSnippetStyle.ThreeColumns:
      return GridDirection.Column;
    default:
      return GridDirection.Row;
  }
};

interface IArticleSnippet {
  id: number;
  heading?: string;
  background: boolean;
  layout?: ArticleSnippetStyle;
  articles?: Array<IArticleSummary>;
  article?: IArticleSummary;
}

export const ArticleSnippet: React.FC<IArticleSnippet> = ({
  background,
  heading,
  articles,
  article,
  layout = ArticleSnippetStyle.FullWidth,
}) => {
  return (
    <div
      className={
        background ? cx(styles.bgImage, styles[layout], styles[`${layout}_BG`]) : styles[layout]
      }
    >
      {background && <BackgroundBannerImage className={cx(styles.background)} />}
      {layout === ArticleSnippetStyle.SlidesShow ? (
        <>
          {heading ? (
            <div className={styles.title}>
              <h5>{heading}</h5>
            </div>
          ) : null}
          <ImageSlider articles={articles} />
        </>
      ) : null}
      {layout !== ArticleSnippetStyle.SlidesShow && (
        <CMSChildrenContainer>
          {article ? (
            <div
              className={
                background
                  ? cx(styles.bgImage, cmsStyles.containerPaddingS)
                  : cmsStyles.containerMarginS
              }
            >
              {heading && (
                <div className={styles.title}>
                  <h5>{heading}</h5>
                  {background ? <hr className={styles.line} /> : null}
                </div>
              )}
              <ArticleSnippetItem
                article={article}
                withBackground={background}
                style={ArticleSnippetStyle.FullWidth}
                gridDirection={styleToGridDirection(layout)}
              />
            </div>
          ) : null}
          {articles ? (
            <div
              className={
                background
                  ? cx(styles.bgImage, cmsStyles.containerPaddingS)
                  : cmsStyles.containerMarginS
              }
            >
              <div className={styles[layout]}>
                {heading ? (
                  <div className={styles.title}>
                    <h5>{heading}</h5>
                    {background && <hr className={styles.line} />}
                  </div>
                ) : null}

                {layout === ArticleSnippetStyle.ThreeColumns ||
                layout === ArticleSnippetStyle.TwoColumns ||
                layout === ArticleSnippetStyle.Vertical ? (
                  <Grid container spacing={3} className={styles.articles}>
                    {articles.map(articleData => (
                      <Grid key={articleData.id} item xs={12} md={styleToGridColumn(layout)}>
                        <ArticleSnippetItem
                          article={articleData}
                          withBackground={background}
                          gridDirection={styleToGridDirection(layout)}
                          style={layout}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
              </div>
            </div>
          ) : null}
        </CMSChildrenContainer>
      )}
    </div>
  );
};
