import cx from 'classnames';
import React, { CSSProperties } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IArticleSummary, IGeneralImageSlide, ITestimonial } from 'types/cms';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ImageSliderItem from './ImageSliderItem';
import styles from './style.module.scss';

interface IProps {
  testimonials?: ITestimonial[];
  articles?: IArticleSummary[];
  generalImageSliders?: IGeneralImageSlide[];
}

const arrowStyles: CSSProperties = {
  top: 'calc(50% - 15px)',
};

export const ImageSlider: React.FC<IProps> = ({ testimonials, articles, generalImageSliders }) => {
  const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: false,
    stopOnHover: true,
    className: styles.imageSlider,
    centerSlidePercentage: 60,
    renderItem: (
      item: React.ReactNode,
      options?: {
        isSelected: boolean;
      },
    ) => {
      if (options?.isSelected) {
        return <div className={styles.selected}>{item}</div>;
      }
      return <div className={styles.unselected}>{item}</div>;
    },
    renderArrowPrev: (onClickHandler, hasPrev) =>
      hasPrev && (
        <ChevronLeftIcon
          className={styles.arrowStyle}
          style={{ ...arrowStyles }}
          onClick={onClickHandler}
        />
      ),
    renderArrowNext: (onClickHandler, hasNext) =>
      hasNext && (
        <ChevronRightIcon
          className={styles.arrowStyle}
          style={{ right: '15px', ...arrowStyles }}
          onClick={onClickHandler}
        />
      ),
  });

  return (
    <div
      className={cx(
        styles.container,
        styles.containerMarginS,
        articles ? styles.articleSlides : styles.others,
      )}
    >
      {testimonials && (
        <Carousel centerMode {...getConfigurableProps()}>
          {testimonials.map((item, index) => {
            return <ImageSliderItem key={item.id} testimonial={item} order={index + 1} />;
          })}
        </Carousel>
      )}
      {articles && (
        <Carousel centerMode {...getConfigurableProps()}>
          {articles.map((item, index) => {
            return <ImageSliderItem key={item.slug} article={item} order={index + 1} />;
          })}
        </Carousel>
      )}
      {generalImageSliders && (
        <Carousel centerMode {...getConfigurableProps()}>
          {generalImageSliders.map(item => {
            return <ImageSliderItem key={item.id} generalImageSlider={item} />;
          })}
        </Carousel>
      )}
    </div>
  );
};

export default ImageSlider;
