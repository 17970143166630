import CmsImage from '@/cmsComponents/CmsImage';
import cx from 'classnames';
import { PATH } from 'constants/path';
import Link from 'next/link';
import React from 'react';
import { IArticleSummary } from 'types/cms';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ArticlePublisher } from 'components/CMS/ArticlePublisher';
import { ArticleSnippetStyle } from 'components/CMS/ArticleSnippet';
import { GridDirection } from 'components/CMS/constants';
import { defaultAlt } from 'components/Image';

import styles from './style.module.scss';

export interface ArticleSnippetItemProps {
  article: IArticleSummary;
  withBackground: boolean;
  gridDirection?: GridDirection;
  style?: ArticleSnippetStyle;
}

export const ArticleSnippetItem = ({
  article,
  withBackground,
  gridDirection,
  style,
}: ArticleSnippetItemProps) => {
  return (
    <div
      className={cx(
        styles.container,
        style && styles[style],
        withBackground && styles[`${style}_BG`],
        withBackground && styles.containerBG,
      )}
    >
      {article && (
        <Grid container direction={gridDirection ?? 'column'} spacing={3}>
          <Grid item xs={12} md>
            <div className={styles.cover}>
              {article?.cover && (
                <>
                  <CmsImage
                    altText={article.cover.alternativeText || defaultAlt}
                    imageProps={article.cover}
                    format="small"
                    layout="intrinsic"
                    width={940}
                    height={540}
                  />
                  <div className={styles.topLeft}>{article.article_category?.name}</div>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md>
            <div className={styles.info}>
              <div className={styles.title}>
                <Link
                  prefetch={false}
                  href={`${PATH.ARTICLES}/${article.slug}`}
                  as={`${PATH.ARTICLES}/${article.slug}`}
                >
                  <a className={styles.link} href={`${PATH.ARTICLES}/${article?.slug}`}>
                    <Typography variant="h1" component="h5">
                      {article.title}
                    </Typography>
                  </a>
                </Link>
              </div>
              <div className={styles.blurb}>{article.blurb}</div>
              <ArticlePublisher author={article.author} publishedDate={article.published_date} />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ArticleSnippetItem;
