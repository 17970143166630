import cx from 'classnames';
import { PATH } from 'constants/path';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { IGeneralImageSlide, ITestimonial } from 'types/cms';
import { IArticleSummary } from 'types/cms';

import Button from '@material-ui/core/Button';

import styles from './style.module.scss';

const Markdown = dynamic(() => import('react-markdown'));

enum CoverStyle {
  Article = 'article',
  Testimonial = 'testimonial',
  General = 'general',
}

interface ImageSliderItemProps {
  testimonial?: ITestimonial;
  article?: IArticleSummary;
  generalImageSlider?: IGeneralImageSlide;
  order?: number;
}

export const ImageSliderItem = ({
  testimonial,
  article,
  generalImageSlider,
  order,
}: ImageSliderItemProps) => {
  const bgImage = testimonial?.image?.url ?? article?.cover?.url ?? generalImageSlider?.image?.url;
  const coverStyle = article
    ? CoverStyle.Article
    : testimonial
    ? CoverStyle.Testimonial
    : CoverStyle.General;

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: bgImage ? `url(${bgImage})` : undefined,
      }}
    >
      <div className={styles.bgColor}>
        <div className={cx(styles.cover, styles[coverStyle])}>
          {article && (
            <>
              <div className={styles.order}>/ {order && order < 10 ? '0' + order : order}</div>
              <Link
                prefetch={false}
                href={`${PATH.ARTICLES}/${article.slug}`}
                as={`${PATH.ARTICLES}/${article.slug}`}
              >
                <a className={styles.link}>
                  <div className={styles.title}>
                    <h1>{article.title}</h1>
                  </div>
                </a>
              </Link>
              <div className={styles.publish}>
                {article.article_category?.name} /{' '}
                {dayjs(article.published_date).format('MMMM YYYY')}
              </div>
            </>
          )}

          {testimonial && (
            <>
              <Markdown className={styles.quotes} source={testimonial.quote} />
              <div className={styles.publish}>
                {testimonial.author_name} {testimonial.job_title && `, ${testimonial.job_title}`}
              </div>
            </>
          )}

          {generalImageSlider && (
            <>
              {generalImageSlider?.title && (
                <div className={styles.general_image_title}>{generalImageSlider.title}</div>
              )}
              {generalImageSlider?.link && (
                <div className={styles.btnContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    href={generalImageSlider?.link}
                    className={styles.button}
                  >
                    <span className={styles.linkText}>Read Article</span>
                  </Button>
                </div>
              )}
              <div className={styles.content}>{generalImageSlider.content}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageSliderItem;
